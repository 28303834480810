import React from "react";
interface TextInputProps {
  label: string;
  value: string;
  setValue: (value: React.SetStateAction<string> | string) => void;
  required?: boolean;
  color?: string;
}
const TextInput = (props: TextInputProps) => {
  const { label, value, setValue, required = false, color = "#fff" } = props;
  return (
    <div className="displayFlex width margiInputs">
      <label className="form-label labelTitle" style={{ color: color }}>
        {label}
      </label>
      <input
        name="guideTitle"
        className="form-control"
        value={value ? value : ""}
        required={required}
        onChange={(event) => setValue(event.target.value)}
      />
    </div>
  );
};

export default TextInput;
