import React, { useCallback, useEffect, useState } from "react";
import { useUnityContext } from "../../hooks/useUnityContext";
import { useNavigate } from "react-router-dom";

interface SaveButtonProps {
  setIsSubmitted: React.Dispatch<React.SetStateAction<boolean>>;
  isSubmitted: boolean;
}

const SaveButton = (props: SaveButtonProps) => {
  const { isSubmitted, setIsSubmitted } = props;
  const { addEventListener, removeEventListener } = useUnityContext();
  const navigate = useNavigate();
  const [showSuccesAlert, setShowSuccesAlert] = useState(false);
  const [showErrorAlert, setShowErrorAlert] = useState(false);

  const handleSetSuccessfulRequestFeedback = useCallback(() => {
    setShowSuccesAlert(true);

    setTimeout(() => {
      setShowSuccesAlert(false);
      setIsSubmitted(false);
      navigate("/");
    }, 1000);
  }, []);

  useEffect(() => {
    addEventListener(
      "SetSuccessfulRequestFeedback",
      //@ts-ignore
      handleSetSuccessfulRequestFeedback
    );
    return () => {
      removeEventListener(
        "SetSuccessfulRequestFeedback",
        //@ts-ignore
        handleSetSuccessfulRequestFeedback
      );
    };
  }, [
    addEventListener,
    removeEventListener,
    handleSetSuccessfulRequestFeedback,
  ]);

  const handleSetUnsuccessfulRequestFeedback = useCallback(() => {
    setShowErrorAlert(true);
    setTimeout(() => {
      setShowErrorAlert(false);
      setIsSubmitted(false);
    }, 1000);
  }, []);

  useEffect(() => {
    addEventListener(
      "SetUnsuccessfulRequestFeedback",
      //@ts-ignore
      handleSetUnsuccessfulRequestFeedback
    );
    return () => {
      removeEventListener(
        "SetUnsuccessfulRequestFeedback",
        //@ts-ignore
        handleSetUnsuccessfulRequestFeedback
      );
    };
  }, [
    addEventListener,
    removeEventListener,
    handleSetUnsuccessfulRequestFeedback,
  ]);

  return (
    <>
      <button type="submit" className=" width btn btn-success width saveBtn">
        {!isSubmitted && <>Save Guide</>}
        {isSubmitted && (
          <div className="spinner-border text-light" role="status"></div>
        )}
      </button>

      {showSuccesAlert && (
        <div className="alert alert-success width" role="alert">
          Guide successfully saved!
        </div>
      )}

      {showErrorAlert && (
        <div className="alert alert-danger width" role="alert">
          Failed to save guide!
        </div>
      )}
    </>
  );
};

export default SaveButton;
