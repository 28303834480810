import React from "react";
import { useUnityContext } from "../hooks/useUnityContext";

const Header = () => {
  const { isUnityLoaded } = useUnityContext();

  return (
    <>
      <div className="displayFlex width header">
        {isUnityLoaded && (
          <h2 className="leftSideWrapper">
            {window.location.pathname.includes("editGuide")
              ? "Edit Guide"
              : window.location.pathname.includes("newGuide")
              ? "Create Guide"
              : "Guides"}
          </h2>
        )}
        {isUnityLoaded && (
          <div className="displayFlex rightSideWrapper">
            <img
              src={process.env.PUBLIC_URL + "/logo_ariiva-light.png"}
              alt="ARiiVA Logo"
              className="img-fluid"
              width="190"
              height="240"
              style={{ margin: "0 auto" }}
            />
            <p
              className="VersionUpdate"
              style={{
                fontSize: "13px",
                color: "#6AB1A4",
                fontWeight: "bold",
              }}
            >
              v0.1.1
            </p>
          </div>
        )}

        {!isUnityLoaded && (
          <div
            className="displayFlex rightSideWrapper"
            style={{ width: `${!isUnityLoaded && "100%"}` }}
          >
            <img
              src={process.env.PUBLIC_URL + "/logo_ariiva-light.png"}
              alt="ARiiVA Logo"
              className="img-fluid"
              width="190"
              height="240"
              style={{ margin: "0 auto" }}
            />
          </div>
        )}
      </div>
    </>
  );
};

export default Header;
