import React, { useState } from "react";
import {
  DefaultUnityContextProps,
  UnityContext,
  UnityContextProps,
  defaultUnityContextState,
} from "./UnityContext";
import { useUnityContext } from "react-unity-webgl";

interface UnityConfig {
  loaderUrl: string;
  dataUrl: string;
  frameworkUrl: string;
  codeUrl: string;
}

interface UnityContextProviderProps {
  unityConfig: UnityConfig;
  children: React.ReactNode;
}

export const UnityContextProvider: React.FC<UnityContextProviderProps> = (
  props: UnityContextProviderProps
) => {
  const [contextValue, setContextValue] = useState<DefaultUnityContextProps>(
    defaultUnityContextState
  );

  const {
    unityProvider,
    sendMessage,
    isLoaded,
    addEventListener,
    removeEventListener,
    requestFullscreen,
  } = useUnityContext({
    loaderUrl: props.unityConfig.loaderUrl,
    dataUrl: props.unityConfig.dataUrl,
    frameworkUrl: props.unityConfig.frameworkUrl,
    codeUrl: props.unityConfig.codeUrl,
  });

  const setIsUnityLoaded = (unityLoaded: boolean): void => {
    setContextValue({
      ...contextValue,
      isUnityLoaded: unityLoaded,
    });
  };

  const unityContextValue: UnityContextProps = {
    ...contextValue,
    setIsUnityLoaded,
    unityProvider,
    sendMessage,
    isLoaded,
    addEventListener,
    removeEventListener,
    requestFullscreen,
  };

  return (
    <UnityContext.Provider value={unityContextValue}>
      {props.children}
    </UnityContext.Provider>
  );
};
