import React, { FC } from "react";
import { useUnityContext } from "./hooks/useUnityContext";

interface TypeProps {
  type: string;
  setOriginType: React.Dispatch<React.SetStateAction<string>>;
}

const OriginTypeSelect: FC<TypeProps> = (props: TypeProps) => {
  const { type, setOriginType } = props;
  const originTypes = [
    { value: "0", text: "Point" },
    { value: "1", text: "Circle" },
    { value: "2", text: "Square" },
    { value: "3", text: "Area" },
  ];

  const { sendMessage } = useUnityContext();

  function unitySetOriginType(type: string) {
    sendMessage("JavascriptScript", "SetGuideOriginType", type);
    setOriginType(type);
  }

  return (
    <div className="displayFlex width">
      <label className="form-label labelTitle">Origin Type</label>
      <select
        className="form-select"
        value={props.type}
        onChange={(event) => unitySetOriginType(event.target.value)}
      >
        {originTypes.map((originType) => (
          <option
            style={{ color: "black" }}
            key={originType.value}
            value={originType.value}
            selected={originType.value === type}
          >
            {originType.text}
          </option>
        ))}
      </select>
    </div>
  );
};

export default OriginTypeSelect;
