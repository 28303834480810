import React, { useCallback, useEffect, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { useUnityContext } from "../../hooks/useUnityContext";
import TextInput from "../form/TextInput";

interface ModalImageProps {
  resetFullScreen: () => void;
}

const ModalImage = (props: ModalImageProps) => {
  const { resetFullScreen } = props;
  const [imageBoxUrl, setImageBoxUrl] = useState("");
  const { sendMessage, addEventListener, removeEventListener } =
    useUnityContext();

  const [showModalImage, setShowModalImage] = useState(false);

  const [isInvalid, setIsInvalid] = useState(false);
  const [errorText, setErrorText] = useState("Image Url is required");

  const handleCloseModalImage = () => {
    setShowModalImage(false);
    setIsInvalid(false);
    setErrorText("Image Url is required");
  };

  const handleOpenModalImage = () => {
    resetFullScreen();
    setShowModalImage(true);
    setImageBoxUrl("");
  };

  const handleCreateImageModal = useCallback(() => {
    handleOpenModalImage();
  }, []);

  function unitySetImageBox(url: string) {
    sendMessage("JavascriptScript", "PlaceImage", url);
  }

  useEffect(() => {
    //@ts-ignore
    addEventListener("CreateImageModal", handleCreateImageModal);
    return () => {
      //@ts-ignore
      removeEventListener("CreateImageModal", handleCreateImageModal);
    };
  }, [addEventListener, removeEventListener, handleCreateImageModal]);

  //Catch error if URL is invalid....!
  const handleSetUnsuccessfulRequestFeedback = useCallback((e: any) => {
    setShowModalImage(true);
    setIsInvalid(true);
    setErrorText("Image Url is has to be a valid url");
  }, []);

  useEffect(() => {
    addEventListener(
      "SetUnsuccessfulRequestFeedback",
      //@ts-ignore
      handleSetUnsuccessfulRequestFeedback
    );
    return () => {
      removeEventListener(
        "SetUnsuccessfulRequestFeedback",
        //@ts-ignore
        handleSetUnsuccessfulRequestFeedback
      );
    };
  }, [
    addEventListener,
    removeEventListener,
    handleSetUnsuccessfulRequestFeedback,
  ]);

  return (
    <>
      <Modal show={showModalImage} onHide={handleCloseModalImage}>
        <Modal.Header closeButton className="colorBlack">
          <Modal.Title className="colorBlack">
            Enter Image URL(must be a valid url)
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <TextInput
            label=""
            value={imageBoxUrl}
            setValue={setImageBoxUrl}
            color="black"
          ></TextInput>
          {/*  */}
          {isInvalid && <h6 className="error">{errorText}</h6>}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModalImage}>
            Close
          </Button>
          <Button
            variant="primary"
            onClick={() => {
              if (imageBoxUrl === "") {
                setIsInvalid(true);
                setErrorText("Image Url is required");
                return;
              }

              setErrorText("Image Url is required");
              setIsInvalid(false);
              handleCloseModalImage();
              unitySetImageBox(imageBoxUrl);
            }}
          >
            Create
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ModalImage;
