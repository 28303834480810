import React from "react";
import UnityTemplate from "../commonComponents/UnityTemplate";
import FormTemplate from "../commonComponents/form/FormTemplate";
import { useUnityContext } from "../hooks/useUnityContext";
import UnityLoadingScreen from "../UnityLoadingScreen";
import { useParams } from "react-router-dom";

function EditGuide() {
  const { isUnityLoaded } = useUnityContext();
  const params = useParams();

  return (
    <div className="displayFlex width header wraperContent">
      {!isUnityLoaded  && <UnityLoadingScreen />}
      {isUnityLoaded  && <FormTemplate></FormTemplate>}

      <UnityTemplate id={params?.id ? params?.id : null}></UnityTemplate>
    </div>
  );
}
export default EditGuide;
