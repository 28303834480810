import styled from "styled-components";

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 10px 15px;
  background: #fff;
  border-radius: 5px;
  margin: 1rem 1rem 1rem 0rem;

  &:hover {
    background: #f5f5f5;
    cursor: pointer;
  }
`;

export const CardTitle = styled.h4`
    color: #000;
    font-weight: bold;
`;

export const CardSubtitle = styled.h5`
    color: #c1c1c1;
`;

export const UnityLoadingComponent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  font-size: 1.5rem;
`;
