import React, { useCallback, useEffect, useState } from "react";
import { Page } from "../../../models/Page";
import { RemotePage } from "../../../models/remote/RemotePage";
import OriginTypeSelect from "../../OriginTypeSelect";
import ReferenceModelLoader from "./ReferenceModelLoader";
import { useUnityContext } from "../../hooks/useUnityContext";
import SaveButton from "./SaveButton";
import FormPageTiemplate from "./formPages/FormPageTiemplate";
import TextInput from "./TextInput";
import PlaneTemplate from "./formPlane/PlaneTemplate";

const FormTemplate = () => {
  const [guideTitle, setGuideTitle] = useState("");
  const [guideDescription, setGuideDescription] = useState("");
  const [placementDescription, setPlacementDescription] = useState("");
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [model, setModel] = useState<string | null>(null);
  const [originType, setOriginType] = useState("0");
  const [planeType, setPlaneType] = useState(0);
  const REACT_APP_API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
  const [currentPageIndex, setCurrentPageIndex] = useState(0);
  const [pages, setPages] = useState<Page[]>([
    { id: null, title: "", description: "", modelUri: null },
  ]);

  const { sendMessage, addEventListener, removeEventListener } =
    useUnityContext();

  const handleSetGuide = useCallback((guideString: string) => {
    var jsonGuide = JSON.parse(guideString);
    var guide = JSON.parse(jsonGuide["Guide"]);
    setGuideTitle(guide.Title);
    setGuideDescription(guide.Description);
    setPlacementDescription(guide.PlacementDescription);
    setOriginType(guide.OriginType);
    setPlaneType(guide.PlaneType);
    fillPageArray(guide.Pages);

  }, []);

  function fillPageArray(pages: RemotePage[]) {
    var receivedPages: Page[] = Array<Page>();
    pages.forEach((page: RemotePage) => {
      const p = new Page(page.Id, page.Title, page.Description, page.ModelUri);
      receivedPages.push(p);
    });
    setPages(receivedPages);
    // @ts-ignore
    if (receivedPages[currentPageIndex].modelUri !== null && model === null) {
      // @ts-ignore
      setModel(receivedPages[currentPageIndex].modelUri);
      sendMessage(
        "JavascriptScript",
        "LoadGltfModelFromUrl",
        // @ts-ignore
        `${REACT_APP_API_ENDPOINT}/getModelFromPublicFolder/${receivedPages[currentPageIndex].modelUri}`
      );
    }
  }

  function unitySetGuideTitle(title: string) {
    sendMessage("JavascriptScript", "SetGuideTitle", title);
  }

  function unitySetGuideDescription(desc: string) {
    sendMessage("JavascriptScript", "SetGuideDescription", desc);
  }

  function unitySetPlacementDescription(desc: string) {
    sendMessage("JavascriptScript", "SetGuidePlacementDescription", desc);
  }

  function unitySaveGuide() {
    sendMessage("JavascriptScript", "SaveGuide");
  }

  function unityLoadGltfModel(path: string, modelName: string) {
   sendMessage("JavascriptScript", "LoadGltfModelFromUrl", path);
   sendMessage("JavascriptScript", "SetCurrentPageModelUri", modelName);
   pages[currentPageIndex].modelUri = modelName;
  }

  // Form onSubmit
  function handleSubmit(e: any) {
    setIsSubmitted(true);
    // Prevent the browser from reloading the page
    e.preventDefault();

    unitySetGuideTitle(guideTitle);
    unitySetGuideDescription(guideDescription);
    unitySetPlacementDescription(placementDescription);
    unitySaveGuide();
  }

  useEffect(() => {
    //@ts-ignore
    addEventListener("SetExistingGuide", handleSetGuide);
    return () => {
      //@ts-ignore
      removeEventListener("SetExistingGuide", handleSetGuide);
    };
  }, [addEventListener, removeEventListener, handleSetGuide]);

  return (
    <div className="leftSideWrapper">
      <form
        method="post"
        onSubmit={handleSubmit}
        className="displayFlex width form"
      >
        <SaveButton
          isSubmitted={isSubmitted}
          setIsSubmitted={setIsSubmitted}
        ></SaveButton>
        <TextInput
          value={guideTitle}
          setValue={setGuideTitle}
          label="Guide Title"
          required
        ></TextInput>
        <TextInput
          value={guideDescription}
          setValue={setGuideDescription}
          label="Guide Description"
        ></TextInput>

        <TextInput
          value={placementDescription}
          setValue={setPlacementDescription}
          label="Placement Description"
          required
        ></TextInput>

        <OriginTypeSelect type={originType} setOriginType={setOriginType} />
        <PlaneTemplate planeType={planeType} setPlaneType={setPlaneType} />
        <FormPageTiemplate
          setModel={setModel}
          pages={pages}
          setPages={setPages}
          currentPageIndex={currentPageIndex}
          setCurrentPageIndex={setCurrentPageIndex}
        ></FormPageTiemplate>
        <ReferenceModelLoader loadModel={unityLoadGltfModel} model={model}/>
      </form>
    </div>
  );
};

export default FormTemplate;
