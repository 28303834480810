import React from "react";
import PlaneTypeSelect from "../../../PlaneTypeSelect";
import ViewOptions from "../../../ViewOptions";
import { useUnityContext } from "../../../hooks/useUnityContext";

interface PlaneTemplateProps {
  setPlaneType: React.Dispatch<React.SetStateAction<number>>;
  planeType: number;
}

const PlaneTemplate = (props: PlaneTemplateProps) => {
  const { setPlaneType, planeType } = props;
  const { sendMessage } = useUnityContext();

  function unitySetPlaneType(type: string) {
    sendMessage("JavascriptScript", "SetGuidePlaneType", type);
    if (type === "horizontal") {
      setPlaneType(0);
    } else {
      setPlaneType(1);
    }
  }

  function unityToggleAxisState(isActive: boolean) {
    if (isActive) {
      sendMessage("JavascriptScript", "ToggleTransformAxis", 1);
    } else {
      sendMessage("JavascriptScript", "ToggleTransformAxis", 0);
    }
  }

  function unityToggleResizableState(isActive: boolean) {
    if (isActive) {
      sendMessage("JavascriptScript", "SetIsResizable", 1);
    } else {
      sendMessage("JavascriptScript", "SetIsResizable", 0);
    }
  }

  function unityToggleGrid(isActive: boolean) {
    if (isActive) {
      sendMessage("JavascriptScript", "ToggleRealSizeGrid", 1);
    } else {
      sendMessage("JavascriptScript", "ToggleRealSizeGrid", 0);
    }
  }
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        padding: "1rem",
        margin: "20px 0",
      }}
      className="border border-secondary rounded displayFlex width"
    >
      <PlaneTypeSelect planeType={planeType} onChange={unitySetPlaneType} />
      <ViewOptions
        toggleAxis={unityToggleAxisState}
        toggleFloorResize={unityToggleResizableState}
        toggleGrid={unityToggleGrid}
      />
    </div>
  );
};

export default PlaneTemplate;
