import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import LeftArrow from "../../../icons/LeftArrow";
import PlusIcon from "../../../icons/PlusIcon";
import RightArrow from "../../../icons/RightArrow";
import { Page } from "../../../../models/Page";
import { useUnityContext } from "../../../hooks/useUnityContext";
import TextInput from "../TextInput";

interface FormPageTemplateProps {
  setModel: React.Dispatch<React.SetStateAction<string | null>>;
  setPages: React.Dispatch<React.SetStateAction<Page[]>>;
  pages: Page[];
  currentPageIndex: number;
  setCurrentPageIndex: React.Dispatch<React.SetStateAction<number>>;
}

const FormPageTiemplate = (props: FormPageTemplateProps) => {
  const { setPages, pages, currentPageIndex, setCurrentPageIndex, setModel } = props;

  const [currentPage, setCurrentPage] = useState(1);

  const { sendMessage } = useUnityContext();
  
  const REACT_APP_API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

  const handleTitleChange = (value: React.SetStateAction<string> | string) => {
    const newPages = [...pages];
    newPages[currentPageIndex].title = value;
    setPages(newPages);
    // @ts-ignore
    unitySetPageTitle(value);
  };

  const handleDescriptionChange = (
    value: React.SetStateAction<string> | string
  ) => {
    const newPages = [...pages];
    newPages[currentPageIndex].description = value;
    setPages(newPages);
    // @ts-ignore
    unitySetPageDescription(value);
  };

  const handleAddPage = () => {
    setPages([...pages, new Page(pages.length + 1, "", "",  null)]);
    setCurrentPageIndex(pages.length);
    setCurrentPage(pages.length + 1);

    unityCreateNewPage();
  };

  const handlePrevPage = () => {
    const position = currentPageIndex;
    setCurrentPageIndex(position - 1);
    setCurrentPage(position);

    unityPrevPage();
  };

  const handleNextPage = () => {
    const position = currentPageIndex;
    setCurrentPageIndex(position + 1);
    setCurrentPage(position + 2);

    unityNextPage();
  };

  function unitySetPageTitle(title: string) {
    sendMessage("JavascriptScript", "SetCurrentPageTitle", title);
  }

  function unitySetPageDescription(desc: string) {
    sendMessage("JavascriptScript", "SetCurrentPageDescription", desc);
  }

  function unityCreateNewPage() {
    sendMessage("JavascriptScript", "CreateNewPage");
    sendMessage("JavascriptScript", "LoadGltfModelFromUrl", "reset");
    setModel(null)
  }

  function unityPrevPage() {
    sendMessage("JavascriptScript", "PrevPage");

    if(pages[currentPageIndex-1]?.modelUri){
      sendMessage("JavascriptScript", "LoadGltfModelFromUrl", "reset");
      sendMessage(
        "JavascriptScript",
        "LoadGltfModelFromUrl",
        `${REACT_APP_API_ENDPOINT}/getModelFromPublicFolder/${pages[currentPageIndex-1]?.modelUri}`
      );
      setModel(pages[currentPageIndex-1]?.modelUri)
    }else{
      sendMessage("JavascriptScript", "LoadGltfModelFromUrl", "reset");
      setModel(null)
    }
  }

  function unityNextPage() {
    sendMessage("JavascriptScript", "NextPage");
    if(pages[currentPageIndex+1]?.modelUri){
      sendMessage("JavascriptScript", "LoadGltfModelFromUrl", "reset");
      sendMessage(
        "JavascriptScript",
        "LoadGltfModelFromUrl",
        `${REACT_APP_API_ENDPOINT}/getModelFromPublicFolder/${pages[currentPageIndex+1]?.modelUri}`
      );
      setModel(pages[currentPageIndex+1]?.modelUri)
    }else{
      sendMessage("JavascriptScript", "LoadGltfModelFromUrl", "reset");
      setModel(null)
    }
  }

  return (
    <div
      style={{ scale: "1", padding: "1rem", marginBottom: "20px" }}
      className="border border-secondary rounded displayFlex width"
    >
      <label className="form-label sectionTitle">
        Page {currentPage} / {pages.length === 0 ? 1 : pages.length}
      </label>
      <br></br>

      <TextInput
        label="Page Title"
        value={pages[currentPageIndex]?.title}
        setValue={handleTitleChange}
        required
      ></TextInput>

      <TextInput
        label="Page TiDescriptionle"
        value={pages[currentPageIndex]?.description}
        setValue={handleDescriptionChange}
      ></TextInput>

      <div
        className="displayFlex width extraMarginBottom extraMarginTop"
        style={{
          justifyContent: "space-between",
        }}
      >
        <div>
          <Button
            onClick={handlePrevPage}
            disabled={currentPageIndex === 0}
            style={{
              backgroundColor: "#168f84",
              border: "black",
            }}
          >
            <LeftArrow />
          </Button>
          <span style={{ marginLeft: "1rem" }}></span>
          <Button
            onClick={handleNextPage}
            disabled={currentPageIndex === pages.length - 1}
            style={{
              backgroundColor: "#168f84",
              border: "black",
            }}
          >
            <RightArrow />
          </Button>
        </div>

        <Button
          onClick={handleAddPage}
          style={{
            backgroundColor: "#168f84",
            border: "black",
          }}
        >
          <PlusIcon />
          Add Page
        </Button>
      </div>
    </div>
  );
};

export default FormPageTiemplate;
