import React, { FC, useEffect } from "react";

interface OptionsProps {
  toggleAxis(checked: boolean): any;
  toggleFloorResize(checked: boolean): any;
  toggleGrid(checked: boolean): any;
}

const ViewOptions: FC<OptionsProps> = (props: OptionsProps) => {
  useEffect(() => {
    props.toggleAxis(true);
  }, []);
  return (
    <div>
      <label className="form-label labelTitle">Options</label>
      <div className="form-check form-switch">
        <input
          className="form-check-input"
          type="checkbox"
          role="switch"
          defaultChecked={true}
          onChange={(event) => props.toggleAxis(event.target.checked)}
        />
        <label
          className="form-check-label labelText"
          htmlFor="flexSwitchCheckDefault"
        >
          Object Editor
        </label>
      </div>

      <div className="form-check form-switch">
        <input
          className="form-check-input"
          type="checkbox"
          role="switch"
          id="flexSwitchCheckDefault"
          onChange={(event) => props.toggleFloorResize(event.target.checked)}
        ></input>
        <label
          className="form-check-label labelText"
          htmlFor="flexSwitchCheckDefault"
        >
          Resize Floor
        </label>
      </div>

      <div className="form-check form-switch">
        <input
          className="form-check-input"
          type="checkbox"
          role="switch"
          id="flexSwitchCheckDefault"
          onChange={(event) => props.toggleGrid(event.target.checked)}
        ></input>
        <label
          className="form-check-label labelText"
          htmlFor="flexSwitchCheckDefault"
        >
          Show Grid
        </label>
      </div>
    </div>
  );
};

export default ViewOptions;
