import React, { FC } from "react";

interface TypeProps {
  planeType: number;
  onChange(type: string): any;
}

const PlaneTypeSelect: FC<TypeProps> = (props: TypeProps) => {
  return (
    <div className="mb-3">
      <label className="form-label labelTitle">Alignment</label>
      <div className="form-check">
        <input
          className="form-check-input"
          type="radio"
          name="flexRadioDefault"
          checked={props.planeType === 0}
          value="horizontal"
          onClick={() => props.onChange("horizontal")}
        />
        <label
          className="form-check-label labelText"
          htmlFor="flexRadioDefault"
        >
          Floor
        </label>
      </div>
      <div className="form-check">
        <input
          className="form-check-input"
          type="radio"
          name="flexRadioDefault"
          value="vertical"
          checked={props.planeType === 1}
          onClick={() => props.onChange("vertical")}
        />
        <label
          className="form-check-label labelText"
          htmlFor="flexRadioDefault"
        >
          Wall
        </label>
      </div>
    </div>
  );
};

export default PlaneTypeSelect;
