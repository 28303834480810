import React, { FC } from "react";
import { Card, CardSubtitle, CardTitle } from "../styling/StylingForComponents";

interface GuideProps {
  Id: number;
  Title: string;
  Description: string;
  loadGuide(id: any): any;
  loadUnity?: any;
}

const GuideCard: FC<GuideProps> = (props: GuideProps) => {
  return (
    <Card
      className="displayFlex width"
      key={props.Id}
      onClick={() => {
        props.loadGuide(props.Id);
        props.loadUnity();
      }}
    >
      <CardTitle>{props.Title}</CardTitle>
      <CardSubtitle>{props.Description}</CardSubtitle>
    </Card>
  );
};

export default GuideCard;
