import React from "react";
import "./App.css";
import { UnityContextProvider } from "./components/context/UnityContextProvider";
import { RouterProvider } from "react-router-dom";
import Router from "./components/router/Router";
import { GlobalStyle } from "./styles/GlobalStyle";
import Header from "./components/Pages/Header";

function App(this: any) {
  return (
    <UnityContextProvider
      unityConfig={{
        loaderUrl: "/build/WebGLBuild.loader.js",
        dataUrl: "/build/WebGLBuild.data",
        frameworkUrl: "/build/WebGLBuild.framework.js",
        codeUrl: "/build/WebGLBuild.wasm",
      }}
    >
      <GlobalStyle />
      <Header></Header>
      <RouterProvider router={Router()} />
    </UnityContextProvider>
  );
}

export default App;
