import React, { useCallback, useEffect, useRef } from "react";
import { Unity } from "react-unity-webgl";
import { useUnityContext } from "../hooks/useUnityContext";
import ModalsTemplate from "./unityModals/ModalsTemplate";

interface UnityTemplateProps {
  id: string | null;
}

const UnityTemplate = (props: UnityTemplateProps) => {
  const { id } = props;
  const {
    unityProvider,
    sendMessage,
    isLoaded,
    addEventListener,
    removeEventListener,
    isUnityLoaded,
    setIsUnityLoaded,
    requestFullscreen,
  } = useUnityContext();

  // Hook for receiving clicks outside of WebGL
  const wrapperRef = useRef<HTMLCanvasElement | null>(null);
  useOutsideAlerter(wrapperRef);
  /**
   * Hook that alerts clicks outside of the passed ref
   */
  function useOutsideAlerter(ref: any) {
    useEffect(() => {
      function handleClickOutside(event: any) {
        if (ref.current && !ref.current.contains(event.target)) {
          handleClickOutsideGame();
        }
      }

      // Bind the event listener
      document.addEventListener("pointerdown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("pointerdown", handleClickOutside);
      };
    }, [ref, isLoaded]);
  }

  function handleClickOutsideGame() {
    if (
      Unity !== undefined &&
      isLoaded &&
      isUnityLoaded &&
      wrapperRef.current !== null &&
      unityProvider
    ) {
    sendMessage("JavascriptScript", "FocusWeb");
    }
  }

  const handleUnityIsLoaded = useCallback(() => {
    setIsUnityLoaded(true);
  }, []);

  function unityLoadGuide(id: string) {
    sendMessage("JavascriptScript", "LoadGuide", parseInt(id));
  }

  useEffect(() => {
    //@ts-ignore
    addEventListener("UnityIsLoaded", handleUnityIsLoaded);
    return () => {
      //@ts-ignore
      removeEventListener("UnityIsLoaded", handleUnityIsLoaded);
    };
  }, [addEventListener, removeEventListener, handleUnityIsLoaded]);

  //Load guide on Edit...
  useEffect(() => {
    if (
      Unity !== undefined &&
      id &&
      isLoaded &&
      isUnityLoaded &&
      wrapperRef.current !== null &&
      unityProvider
    ) {
      unityLoadGuide(id);
      handleClickOutsideGame();
    }
  }, [isUnityLoaded, isLoaded, unityProvider, id]);

  return (
    <>
      <div className="displayFlex rightSideWrapper forceCenter">
        {isUnityLoaded && (
          <button
            className="btn btn-primary btnFullScreen"
            onClick={() => {
              requestFullscreen(true);
            }}
          >
            Enter Fullscreen
          </button>
        )}

        {unityProvider?.unityConfig && (
          <Unity
            unityProvider={unityProvider}
            ref={wrapperRef}
            style={{
              display: `${!isUnityLoaded ? "none" : "flex"}`,
              width: "100%",
              cursor: "default",
              aspectRatio: "2.2 / 1",
            }}
            matchWebGLToCanvasSize={true}
          />
        )}

        <ModalsTemplate
          resetFullScreen={() => {
            requestFullscreen(false);
          }}
        ></ModalsTemplate>
      </div>
    </>
  );
};

export default UnityTemplate;
