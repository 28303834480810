import { createContext } from "react";
import { ReactUnityEventParameter } from "react-unity-webgl/distribution/types/react-unity-event-parameters";
import { UnityProvider } from "react-unity-webgl/distribution/types/unity-provider";

export interface DefaultUnityContextProps {
  isUnityLoaded: boolean;
}

export interface UnityContextProps extends DefaultUnityContextProps {
  sendMessage: (
    gameObjectName: string,
    methodName: string,
    parameter?: ReactUnityEventParameter
  ) => void;
  isLoaded: boolean;
  addEventListener: (
    eventName: string,
    callback: (
      ...parameters: ReactUnityEventParameter[]
    ) => ReactUnityEventParameter
  ) => void;
  removeEventListener: (
    eventName: string,
    callback: (
      ...parameters: ReactUnityEventParameter[]
    ) => ReactUnityEventParameter
  ) => void;
  unityProvider: UnityProvider;
  isUnityLoaded: boolean;
  setIsUnityLoaded: (isLoaded: boolean) => void;
  requestFullscreen: (enabled: boolean) => void;
}
export const defaultUnityContextState: DefaultUnityContextProps = {
  isUnityLoaded: false,
};

export const UnityContext = createContext<UnityContextProps | null>({
  ...defaultUnityContextState,
  sendMessage: () => {},
  isLoaded: false,
  addEventListener: () => {},
  removeEventListener: () => {},
  unityProvider: {} as UnityProvider,
  isUnityLoaded: false,
  setIsUnityLoaded: () => {},
  requestFullscreen: () => {},
});
