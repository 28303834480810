import React, { useEffect } from "react";
import Guides from "../commonComponents/Guides";
import UnityLoadingScreen from "../UnityLoadingScreen";
import { useUnityContext } from "../hooks/useUnityContext";

const Home = () => {
  const { isUnityLoaded, setIsUnityLoaded } = useUnityContext();

  useEffect(() => {
    setTimeout(() => {
      setIsUnityLoaded(true);
    }, 1500);
  }, []);

  return (
    <>
      {!isUnityLoaded && <UnityLoadingScreen />}

      {isUnityLoaded && (
        <div className="displayFlex width header wraperContent">
          <div className="leftSideWrapper">
            <Guides></Guides>
          </div>
          <div className="displayFlex rightSideWrapper">
            <div className="displayFlex width height forceCenter extraMargin">
              <h2 style={{ textAlign: "center" }}>
                Create a new guide or select an existing one
              </h2>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Home;
