export class Page {
    id: number | any;
    title: string | any;
    description: string | any;
    modelUri: string | any;

    constructor(id: number, title: string, description: string, modelUri: string| null) {
        this.id = id;
        this.title = title;
        this.description = description;
        this.modelUri = modelUri;
    }
}