import React, { useEffect } from "react";
import UnityTemplate from "../commonComponents/UnityTemplate";
import FormTemplate from "../commonComponents/form/FormTemplate";
import { useUnityContext } from "../hooks/useUnityContext";
import UnityLoadingScreen from "../UnityLoadingScreen";

const NewGuide = () => {
  const { isUnityLoaded, isLoaded,setIsUnityLoaded } = useUnityContext();

  useEffect(() => {
    if(isLoaded){
      setIsUnityLoaded(true);
    }
  }, [isLoaded]);

  return (
    <>
      <div className="displayFlex width header wraperContent">
        {!isUnityLoaded  && <UnityLoadingScreen />}
        {isUnityLoaded && <FormTemplate></FormTemplate>}
        <UnityTemplate id={null}></UnityTemplate>
      </div>
    </>
  );
};

export default NewGuide;
