import React, { FC } from "react";
import styled, { keyframes } from "styled-components";
import { UnityLoadingComponent } from "../styling/StylingForComponents";

const spin = keyframes`
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
    `;

const LoadingSpinner = styled.div`
  width: 100px;
  height: 100px;
  border: 8px solid #f3f3f3; /* Light grey */
  border-top: 8px solid #383636; /* Black */
  border-radius: 50%;
  animation: ${spin} 1.5s linear infinite;
  margin-bottom: 10px;
`;

const UnityLoadingScreen: FC = () => {
  return (
    <div
      className="displayFlex width forceCenter"
      style={{ marginTop: "5rem" }}
    >
      <UnityLoadingComponent style={{ textAlign: "center" }}>
        <LoadingSpinner />
        Loading ARiiVA AR Guide Configurator
      </UnityLoadingComponent>
    </div>
  );
};

export default UnityLoadingScreen;
