import ModalImage from "./ModalImage";
import ModalText from "./ModalText";
interface ModalTemplateProps {
  resetFullScreen: () => void;
}
const ModalsTemplate = (props: ModalTemplateProps) => {
  const { resetFullScreen } = props;
  return (
    <>
      <ModalText resetFullScreen={resetFullScreen}></ModalText>
      <ModalImage resetFullScreen={resetFullScreen}></ModalImage>
    </>
  );
};

export default ModalsTemplate;
