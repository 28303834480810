import React, { useEffect, useState } from "react";

import EmptyGuideCard from "../EmptyGuideCard";
import GuideCard from "../GuideCard";
import { RemoteGuide } from "../../models/remote/RemoteGuide";
import styled from "styled-components";
import { useUnityContext } from "../hooks/useUnityContext";
import { useNavigate } from "react-router-dom";

const GuidCardList = styled.div`
  height: calc(100% - 56px);
  overflow-y: auto;

  @media (max-width: 920px) {
    height: 600px;
    margin-top: 20px;
  }
`;

const Guides = () => {
  const navigate = useNavigate();
  const [guides, setGuides] = useState(Array<RemoteGuide>);
  const { isUnityLoaded, setIsUnityLoaded, sendMessage } = useUnityContext();

  const { REACT_APP_API_ENDPOINT } = process.env;

  function unityLoadGuide(id: string) {
    sendMessage("JavascriptScript", "LoadGuide", parseInt(id));
  }

  useEffect(() => {
    //fetch("https://arguide.ariiva.at/arguide.php?mode=getAll")
    fetch(`${REACT_APP_API_ENDPOINT}/getAll`)
      .then((res) => res.json())
      .then(
        (result) => {
          setIsUnityLoaded(true);
          var guideList = (result as Array<RemoteGuide>).sort(
            (a, b) => Date.parse(b.ModifiedAt) - Date.parse(a.ModifiedAt)
          );
          setGuides(guideList);
        },
        (error) => {
          setIsUnityLoaded(true);
        }
      );
  }, []);

  return (
    <>
      {isUnityLoaded && (
        <EmptyGuideCard
          newGuide={() => {
            setIsUnityLoaded(false);
            navigate("/newGuide");
            //window.location.reload();
          }}
        />
      )}

      {!isUnityLoaded && <div>LOADING...</div>}
      {guides.length > 0 && (
        <GuidCardList className="displayFlex width">
          {guides.map((guide) => {
            return (
              <GuideCard
                key={guide.Id}
                Id={guide.Id}
                Title={guide.Title}
                Description={guide.Description}
                loadGuide={unityLoadGuide}
                loadUnity={() => {
                  setIsUnityLoaded(false);
                  navigate("/editGuide/" + guide.Id);
                  window.location.reload();
                }}
              />
            );
          })}
        </GuidCardList>
      )}
    </>
  );
};

export default Guides;
