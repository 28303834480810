import React, { FC, useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import {
  Autocomplete,
  AutocompleteRenderInputParams,
  TextField,
} from "@mui/material";
import { useUnityContext } from "../../hooks/useUnityContext";

interface ModelLoaderProps {
  loadModel(type: string, modelName: string): any;
  model: string | null;
}

const ReferenceModelLoader: FC<ModelLoaderProps> = (
  props: ModelLoaderProps
) => {
  const [modelPath, setModelPath] = useState("");
  const [model, setModel] = useState(null);
  const [modelOptions, setModelOptions] = useState([]);
  const REACT_APP_API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
  const { sendMessage, addEventListener, removeEventListener } =
    useUnityContext();
  const saveModelToPublic = () => {
    if (!model) {
      console.error("No file selected.");
      return;
    }

    fetch(`${REACT_APP_API_ENDPOINT}/saveModelToPublic`, {
      method: "POST",
      // @ts-ignore
      body: JSON.stringify({ model: model.Key }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((result) => {
        props.loadModel(
          `${REACT_APP_API_ENDPOINT}/getModelFromPublicFolder/${
            // @ts-ignore
            model.Key.split("/")[1]
          }`,
          // @ts-ignore
          model.Key.split("/")[1]
        );
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    fetch(`${REACT_APP_API_ENDPOINT}/bucketObjectsList`)
      .then((res) => res.json())
      .then(
        (result) => {
          const filteredResult = result.Contents.filter((item: any) => {
            if (item?.Size === 0) {
              return;
            } else {
              return item;
            }
          });
          setModelOptions(filteredResult);
        },
        (error) => {
          console.log(error);
        }
      );
  }, []);

  useEffect(() => {
    if (props.model === null) {
      setModel(null);
      // @ts-ignore
      setModelPath("");
    }
    if (
      props.model !== null &&
      model === null &&
      modelPath === "" &&
      modelOptions.length > 0
    ) {
      const selectedModel = modelOptions.find((item: any) => {
        return item.Key === "arguide/" + props.model;
      });
      if (selectedModel) {
        setModel(selectedModel);
        // @ts-ignore
        setModelPath(selectedModel.Key);
      }
    }
  }, [props.model, modelOptions]);

  return (
    <>
      <Autocomplete
        className="autocomplete colorBlack"
        fullWidth
        renderInput={(params: AutocompleteRenderInputParams) => (
          <TextField
            {...params}
            style={{ padding: "0" }}
            className="colorBlack"
          />
        )}
        options={modelOptions.map((option: any) => option)}
        getOptionLabel={(option: any) => {
          return option.Key;
        }}
        renderOption={(props, option) => {
          return (
            <span {...props} style={{ color: "black" }}>
              {option.Key}
            </span>
          );
        }}
        value={model}
        onChange={(event, value) => {
          setModel(value);
          setModelPath(value.Key);
        }}
      ></Autocomplete>

      <Button
        onClick={(event) => {
          event.preventDefault();
          saveModelToPublic();
        }}
        style={{
          backgroundColor: "#168f84",
          margin: "0.5rem 0 1.5em 0",
          border: "black",
          width: "100%",
        }}
      >
        Load Model
      </Button>
    </>
  );
};

export default ReferenceModelLoader;
