import React, { FC } from "react";

interface EmptyGuideProps {
  newGuide(): any;
}

const EmptyGuideCard: FC<EmptyGuideProps> = (props: EmptyGuideProps) => {
  return (
    <div
      className="width newGuideButton"
      onClick={() => {
        props.newGuide();
      }}
    >
      Create a new guide
    </div>
  );
};

export default EmptyGuideCard;
