import { createGlobalStyle } from "styled-components";

export const GlobalStyle = createGlobalStyle`

//Common style
*{
    padding: 0;
    margin: 0;
    overflow: hidden;
    box-sizing: border-box;
    
}

html, body{
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: flex-start;
    align-content: flex-start;
    color: #fff;
}

body{
    background-color: rgb(30, 30, 30);
}

#root {
    position: relative;
    width: 95%;
    height: 100%;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: flex-start;
    align-content: flex-start;
}
.colorBlack{
    color: #000;
}
.error{
    color: red;
    margin-top: 5px;
    font-weight: 400;
}

.displayFlex{
    position: relative;
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    align-items: flex-start;
    align-content: flex-start;
}
.width{
    position: relative;
    width: 100%;
}

.height{
    height: 100%;
}

.forceCenter{
    justify-content: center;
    align-items: center;
    align-content: center;
}
.extraMarginBottom{
    margin-bottom: .7rem;
}
.extraMarginTop{
    margin-top: 1.4rem;
}

//Header Style
.header{
    height: 50px;
    margin-top: 50px;
    justify-content: space-between;
}
.leftSideWrapper{
    width: 350px;
    text-align: center;
    height: 100%;
}
.rightSideWrapper{
    width: calc(100% - 365px);
    justify-content: center;
    min-width: 190px;
    height: 100%;
}

//Wrapper for everything after header

.wraperContent{
    height: calc(100% - 70px);
    margin: 0;
    padding: 50px 0;
}



//New Guide Button and wrapper
.newGuideButton{
    background-color: rgb(255, 255, 255);
    border-radius: 5px;
    color: black;
    padding: 5px;
    margin-bottom: 20px;
    font-size: 1.5em;
    font-weight: 700;
}

//Forn

.form{
    padding-right: 16px;
    overflow: auto;
    height: 100%;
}

.saveBtn{ 
    margin-bottom: 1rem;
    background-color:rgb(22, 143, 132);
    border: black;
}

.margiInputs{
    margin: 0.7rem 0;
}

//btnFullScreen

.btnFullScreen{
    position: absolute;
    background-color: rgb(22, 143, 132);
    border: black;
    top: 0;
    right: 0;
    }

//Autocomplete    
.autocomplete{
    background-color: #fff;
    margin-bottom: 10px;
    border-radius: 5px;
    color: #000;
    padding: 0;
}


@media (max-width: 1024px) {
    #root {
        padding: 0 2%;
        width: 100%;
    }

    .wraperContent{
        height: auto;
    }
}

@media (max-width: 920px) {
    #root {
        overflow-y: scroll;
    }
    //Header Style
    .header{
        height: 110px;
        margin: 20px 0;
    }
    .leftSideWrapper{
        width: 100%;
        height: auto;
    }
    .rightSideWrapper{
        width: 100%;
        margin-top: 20px;
        height: auto;
    }

    .wraperContent{
        height: auto;
        padding: 20px 0;
    }

    .extraMargin{
        margin-top: 50px;
    }

    }
`;
