import React, { ReactElement, useCallback, useEffect, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { useUnityContext } from "../../hooks/useUnityContext";
import TextInput from "../form/TextInput";

interface ModalTextProps {
  resetFullScreen: () => void;
}

const ModalText = (props: ModalTextProps): ReactElement => {
  const { resetFullScreen } = props;
  const [textBoxTitle, setTextBoxTitle] = useState("");
  const [textBoxDescription, setTextBoxDescription] = useState("");

  const [showModalText, setShowModalText] = useState(false);
  const [isInvalid, setIsInvalid] = useState(false);

  const { sendMessage, addEventListener, removeEventListener } =
    useUnityContext();

  const handleCloseModalText = () => {
    setShowModalText(false);
    setIsInvalid(false);
  };

  const handleOpenModalText = () => {
    resetFullScreen();
    setShowModalText(true);
    setTextBoxTitle("");
    setTextBoxDescription("");
  };

  const handleCreateTextModal = useCallback(() => {
    handleOpenModalText();
  }, []);

  function unitySetTextBoxTitle(title: string) {
    sendMessage("JavascriptScript", "SetTextBoxTitle", title);
  }

  function unitySetTextBoxDescription(desc: string) {
    sendMessage("JavascriptScript", "SetTextBoxDescription", desc);
  }

  function unitySetTextBox() {
    sendMessage("JavascriptScript", "PlaceTextBox");
  }

  useEffect(() => {
    //@ts-ignore
    addEventListener("CreateTextModal", handleCreateTextModal);
    return () => {
      //@ts-ignore
      removeEventListener("CreateTextModal", handleCreateTextModal);
    };
  }, [addEventListener, removeEventListener, handleCreateTextModal]);

  return (
    <>
      <Modal show={showModalText} onHide={handleCloseModalText}>
        <Modal.Header closeButton className="colorBlack">
          <Modal.Title className="colorBlack">Enter Text</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <TextInput
            label="Title"
            value={textBoxTitle}
            setValue={setTextBoxTitle}
            color="black"
          ></TextInput>
          {isInvalid && <h6 className="error">Text Title is required</h6>}
          <TextInput
            label="Description"
            value={textBoxDescription}
            setValue={setTextBoxDescription}
            color="black"
          ></TextInput>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModalText}>
            Close
          </Button>
          <Button
            variant="primary"
            onClick={() => {
              if (textBoxTitle === "") {
                setIsInvalid(true);
                return;
              }
              setIsInvalid(false);
              handleCloseModalText();
              unitySetTextBoxTitle(textBoxTitle);
              unitySetTextBoxDescription(textBoxDescription);
              unitySetTextBox();
            }}
          >
            Create
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ModalText;
