import React from "react";
import { createBrowserRouter } from "react-router-dom";
import Home from "../Pages/Home";
import NewGuide from "../Pages/NewGuide";
import EditGuide from "../Pages/EditGuide";

const Router = () => {
  const router = createBrowserRouter([
    {
      path: "/",
      element: <Home />,
      children: [],
    },
    {
      path: "newGuide",
      element: <NewGuide />,
      children: [],
    },
    {
      path: "editGuide/:id",
      element: <EditGuide />,
      children: [],
    },
  ]);

  return router;
};

export default Router;
