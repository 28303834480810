import { useContext } from "react";
import { UnityContextProps, UnityContext } from "../context/UnityContext";

export const useUnityContext = (): UnityContextProps => {
  const context = useContext(UnityContext);
  if (!context) {
    throw new Error(
      "useUnityContext must be used within a UnityContextProvider"
    );
  }
  return context;
};
